import React from "react"

import Styled from "styled-components"

import { rhythm } from "../utils/typography"

const types = {
  frontend: [
    "tech-stack-s-html5-logo.png",
    "tech-stack-s-css3-logo.png",
    "tech-stack-s-javascript-logo2.png",
    "tech-stack-s-reactjs-logo.svg",
    "tech-stack-s-angular-logo.png",
    "tech-stack-s-gatsbyjs-logo.svg",
    // "tech-stack-s-babel-logo.png",
    // "tech-stack-s-gulpjs-logo.png",
    "tech-stack-s-wordpress-logo.png"
  ],
  backend: [
    "tech-stack-s-nodejs-logo.png",
    "tech-stack-s-php7-logo.png",
    "tech-stack-s-postgresql-logo.png",
    "tech-stack-s-mongodb-logo.png",
    "tech-stack-s-docker-logo.png",
    "tech-stack-s-mysql-logo.png",
    "tech-stack-s-graphql-logo.png",
    "tech-stack-s-aws-logo.png",
    "tech-stack-s-firebase-logo.png",
    // "tech-stack-s-ovh-logo.png",
    "tech-stack-s-1and1-ionos-logo.png"
  ],
  // TODO
  tools: [
    // "gitlab",
    // "bitbucket",
    // "codeship",
    // "git"
  ],
  all: []
}
types.all = [
  ...types.frontend,
  ...types.backend,
  ...types.tools
];

//TODO array unique - no repeated

const Wrapper = Styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: ${rhythm(1 / 2)};
  background: transparent;
  object-fit: fill;
`
const Logo = Styled.div`
  margin: ${rhythm(1 / 2)};
  min-width: 32px;
  height: 32px;
  img {
    // min-width: 32px;
    height: 32px;
    filter: grayscale(100%);
  }
`

const TechStackBar = ({ type }) => {
  type = type || 'all';
  if( !types[type]) {
    return;
  }

  const logos = types[type];

  return (
    <Wrapper>
      {logos.map(logo => {
        const src = `./images-techstack/s/${logo}`
        const alt =
          `Desarrollo de aplicaciones con ` + logo.replace(/-logo.*$/, "")
        return (
          <Logo key={logo}>
            <img src={src} alt={alt} />
          </Logo>
        )
      })}
    </Wrapper>
  )
}

export default TechStackBar

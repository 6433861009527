import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ContentBlock from "../components/contentBlock"
import Breadcrumbs from "../components/breadcrumbs"
import BreadcrumbsBar from "../components/breadcrumbsBar"
import Title from "../components/title"
import Subtitle from "../components/subtitle"
import TechStackBar from "../components/techStackBar"
import Bio from "../components/bio"

class DesarrolloAplicaciones extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Desarrollo de Aplicaciones. Frontend - Programación & Diseño UX & Testing. Backend - Programación + Testing" />

        <ContentBlock theme="blueGreen" angle>

          <BreadcrumbsBar>
            <Breadcrumbs name="FeedYourWeb" page="/" color="#fff" />
            <Breadcrumbs
              name="Desarrollo de Aplicaciones"
              page="/desarrollo-aplicaciones"
              color="#fff"
            />
          </BreadcrumbsBar>
          <Title className="darg-gray">Desarrollo de Aplicaciones <br />Frontend, Backend, Mobile <br />Diseño UX <br />& Testing</Title>
          <p>Los clientes contactan cuando necesitan ayuda con:</p>
        </ContentBlock>

        <ContentBlock theme="white">
          <Subtitle className="darg-gray">
            Frontend - Programación & Diseño UX & Testing
          </Subtitle>
          <ol>
            <li>Desarrollar una aplicación con ReactJS / AngularJS.</li>
            <li>
              Desarrollar una App Móvil multi-plataforma (iPhone + Android +
              Web) con Ionic Framework
            </li>
            <li>
              Conectar su aplicación con un proveedor de servicios como API de
              Facebook, Instragram, Google Maps o pasarelas de pago (Stripe,
              Paypal, Redsys, cualquiera
            </li>
            <li>
              Desarrollar test unitarios (Mocha) o test funcionales e2e
              (Cypress)
            </li>
          </ol>
          <TechStackBar type="frontend" />
        </ContentBlock>

        <ContentBlock theme="gray">
          <Subtitle className="darg-gray">Backend - Programación + Testing</Subtitle>
          <ol>
            <li>Diseñar la arquitectura de una base de datos</li>
            <li>Desarrollar una API GraphQL / REST con NodeJS o PHP</li>
            <li>Desarrollar test unitarios (Mocha)</li>
          </ol>
          <TechStackBar type="backend" />

        </ContentBlock>

        <ContentBlock theme="white">
          <Subtitle className="darg-gray">Consultoría y dirección de proyectos</Subtitle>
          <ol>
            <li>
              Configurar entorno CI / CD para un nuevo proyecto con Gitlab y Gulp.
            </li>
            <li>
              Colaborar con el planning del proyecto, documentación técnica,
              entrenamiento personal
            </li>
            <li>
              Ayudar con la cofiguración de los servidores Linux, como ajustes
              de hosting, docker, usuarios, seguridad, apache, nginx,
              mysql/mariadb/postgre, firewall, mail servers y más
            </li>
            <li>
              Complementar la plantilla de tu empresa cuando hay mucho trabajo
            </li>
          </ol>
          <TechStackBar type="tools" />
        </ContentBlock>

        <ContentBlock>
          <Bio />
        </ContentBlock>
      </Layout>
    )
  }
}

export default DesarrolloAplicaciones

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
